$(window).on("load", function() {
  // Function for navbar hamburger
  $(document).on('click', 'nav .menu-col-mob .hamburger', function() {
      $("nav .menu-col-mob").toggleClass("open");
  });

  // Lazy Load Function
  let lazyload = $(".lzyld");
  new LazyLoad(lazyload);

  // Fancy Gallery
  $('.fancy').fancybox({
    'transitionIn'	:	'elastic',
    'transitionOut'	:	'elastic',
    'speedIn'		:	600,
    'speedOut'		:	200,
    'overlayShow'	:	false
  });

  // Testimonial Slide
  $("#testi-owl").owlCarousel({
    loop: true,
    autoplay: false,
    margin: 20,
    responsiveBaseElement: 'body',
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 7000,
    smartSpeed: 800,
    nav: true,
    responsive: {
      0: {
        items: 1
      },        
      600: {
        items: 1
      },
      1024: {
        items: 3
      },        
      1366: {
        items: 3
      }
    }
  });
});